import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './Login';
import ReactBigCalendar from './ReactBigCalendar';
import MissionList from './MissionList'; // Assurez-vous que ce composant est bien exporté/importé
import RapportComponent from './RapportPrix';
import RapportQte from './RapportQte';
import RapportFacing from './RapportFacing';
import RapportRepture from './RapportRepture';
import GererMarque from './GererMarque';
import Gererarticle from './Gererarticle';
import './styles.css';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ReactBigCalendar" element={<ReactBigCalendar />} />
          <Route path="/missions" element={<MissionList />} />
          <Route path="/RapportPrix" element={<RapportComponent />} />
          <Route path="/RapportQte" element={<RapportQte />} />
          <Route path="/RapportRepture" element={<RapportRepture />} />
          <Route path="/RapportFacing" element={<RapportFacing />} />
          <Route path="/Gererarticle" element={<Gererarticle />} />
          <Route path="/GererMarque" element={<GererMarque />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
