import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Button, Modal, Form } from "react-bootstrap";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const GererMarque = () => {
  const [marques, setMarques] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMarqueId, setSelectedMarqueId] = useState(null);
  const [newMarque, setNewMarque] = useState({
    codeMarque: "",
    intitule: "",
  });

  const fetchMarques = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}Marque/GetMarques`);
      setMarques(response.data);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    fetchMarques();
  }, [fetchMarques]);

  const handleClose = () => {
    setShowModal(false);
    setNewMarque({ codeMarque: "", intitule: "" });
    setIsEditMode(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await axios.put(`${API_BASE_URL}Marque/UpdateMarque/${selectedMarqueId}`, {
            codeMarque: newMarque.codeMarque,
            intitule: newMarque.intitule,
          });
      } else {
        await axios.post(`${API_BASE_URL}Marque/AddMarque`, newMarque);
      }
      fetchMarques();
      handleClose();
    } catch (error) {
        console.error(error);
      setError(error.message);
    }
  };

  const handleDelete = (idMarque) => {
    setSelectedMarqueId(idMarque);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_BASE_URL}Marque/DeleteMarque/${selectedMarqueId}`);
      fetchMarques();
      setShowConfirmModal(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEdit = (marque) => {
    setNewMarque({
      idMarque:"",
      codeMarque: marque.codeMarque,
      intitule: marque.intitule,
    });
    setSelectedMarqueId(marque.idMarque);

    setIsEditMode(true);
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMarque((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Ajouter une marque
        </Button>
      </div>

      <Table striped bordered hover className="custom-table mb-6" >
        <thead>
          <tr>
            <th>Code Marque</th>
            <th>Intitulé</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {marques.length === 0 ? (
            <tr>
              <td colSpan="3" className="text-center">
                Aucune marque disponible
              </td>
            </tr>
          ) : (
            marques.map((marque, index) => (
              <tr key={marque.id || `fallback-key-${index}`}>
                <td>{marque.codeMarque}</td>
                <td>{marque.intitule}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleEdit(marque)}
                    className="me-2"
                  >
                    <i className="bi bi-pencil" style={{ color: "white" }}></i>
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(marque.idMarque)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title">
            {isEditMode ? "Modifier la marque" : "Ajouter une marque"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formCode">
              <Form.Label>Code Marque</Form.Label>
              <Form.Control
                type="text"
                name="codeMarque"
                value={newMarque.codeMarque}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formIntitule" className="mt-3">
              <Form.Label>Intitulé</Form.Label>
              <Form.Control
                type="text"
                name="intitule"
                value={newMarque.intitule}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {isEditMode ? "Modifier" : "Ajouter"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette marque ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GererMarque;
