import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Modal, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import "./styles.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MissionList = () => {
  const [missions, setMissions] = useState([]);
  const [newEvent, setNewEvent] = useState({
    id: null,
    client: "",
    worker: "",
    gouvernorat: "",
    missionDescription: "",
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm:ss"),
    raisonSocial: "",
    adresse: "",
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [missionToDelete, setMissionToDelete] = useState(null);
  const [clients, setClients] = useState([]);
  const [workers, setWorkers] = useState([]);

  const fetchMissions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}Mission/GetMissions`);
      const formattedMissions = response.data.map((mission) => ({
        ...mission,
        date: moment(mission.missionDate).format("YYYY-MM-DD"),
        time: moment(mission.missionTime, "HH:mm:ss").format("HH:mm:ss"),
      }));
      setMissions(formattedMissions);
    } catch (error) {
      console.error("Erreur lors de la récupération des missions :", error);
    }
  };

  useEffect(() => {
    fetchMissions();
  }, []);

  const fetchWorkers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}Merchandiser/GetMerchandisers`
      );
      setWorkers(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des merchandisers :",
        error
      );
    }
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [fetchWorkers]);

  const fetchClients = useCallback(async (workerId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}Merchandiser/GetClientsByMerch/${workerId}`
      );
      setClients(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des clients :", error);
    }
  }, []);

  const addMission = async (mission) => {
    try {
      const formData = new FormData();
      formData.append("client", mission.client);
      formData.append("missionDescription", mission.missionDescription);
      formData.append("userId", mission.worker);
      formData.append("missionDate", mission.date);
      formData.append("missionTime", mission.time);

      await axios.post(`${API_BASE_URL}Mission/AddMission`, formData);
      fetchMissions();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la mission :", error);
    }
  };

  const updateMission = async (mission) => {
    try {
      const formData = new FormData();
      formData.append("client", mission.client);
      formData.append("missionDescription", mission.missionDescription);
      formData.append("userId", mission.worker);
      formData.append("missionDate", mission.date);
      formData.append("missionTime", mission.time);

      await axios.put(
        `${API_BASE_URL}Mission/UpdateMission?missionId=${mission.id}`,
        formData
      );
      fetchMissions();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la mission :", error);
    }
  };

  const deleteMission = async (missionId) => {
    try {
      await axios.delete(
        `${API_BASE_URL}Mission/DeleteMission?missionId=${missionId}`
      );
      fetchMissions();
    } catch (error) {
      console.error("Erreur lors de la suppression de la mission :", error);
    }
  };

  const handleEdit = (mission) => {
    setNewEvent({
      id: mission.missionId,
      client: mission.clientCode,
      worker: mission.userName,
      gouvernorat: mission.gouvernorat,
      missionDescription: mission.missionDescription,
      date: mission.date,
      time: mission.time,
      raisonSocial: mission.raisonSocial,
      adresse: mission.adresse,
    });
    setIsEditMode(true);
    setShowModal(true);
    if (mission.worker) {
      fetchClients(mission.worker);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !newEvent.client ||
      !newEvent.missionDescription ||
      !newEvent.worker ||
      !newEvent.date ||
      !newEvent.time
    ) {
      console.error("Veuillez remplir tous les champs requis.");
      return;
    }

    try {
      if (isEditMode) {
        await updateMission(newEvent);
      } else {
        await addMission(newEvent);
      }
      handleClose();
    } catch (error) {
      console.error(
        "Erreur lors de la soumission :",
        error.response?.data || error.message
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setNewEvent({
      id: null,
      client: "",
      worker: "",
      gouvernorat: "",
      missionDescription: "",
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
      raisonSocial: "",
      adresse: "",
    });
    setIsEditMode(false);
    setClients([]);
  };

  const confirmDelete = (missionId) => {
    setMissionToDelete(missionId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    if (missionToDelete) {
      await deleteMission(missionToDelete); // Passer uniquement l'ID
      setMissionToDelete(null);
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Ajouter une mission
        </Button>
      </div>
      <Table striped bordered hover className="custom-table">
        <thead>
          <tr>
            <th>Client</th>
            <th>Raison Sociale</th>
            <th>Adresse</th>
            <th>Gouvernorat</th>
            <th>Mission</th>
            <th>Marchandiseur</th>
            <th>Date</th>
            <th>Heure</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {missions.length === 0 ? (
            <tr>
              <td colSpan="9" className="text-center">
                Aucune mission disponible
              </td>
            </tr>
          ) : (
            missions.map((mission) => (
              <tr key={mission.missionId}>
                <td>{mission.clientCode}</td>
                <td>{mission.raisonSocial}</td>
                <td>{mission.adresse}</td>
                <td>{mission.gouvernorat}</td>
                <td>{mission.missionDescription}</td>
                <td>{mission.userName}</td>
                <td>{mission.date}</td>
                <td>{mission.time}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleEdit(mission)}
                    className="me-2"
                  >
                    <i className="bi bi-pencil" style={{ color: "white" }}></i>
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => confirmDelete(mission.missionId)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Modifier la mission" : "Ajouter une mission"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formWorker">
              <Form.Label>Merchandiseur</Form.Label>
              <Form.Control
                as="select"
                value={newEvent.worker || ""}
                onChange={(e) => {
                  setNewEvent({ ...newEvent, worker: e.target.value });
                  fetchClients(e.target.value);
                }}
              >
                <option value="">Sélectionnez merchandiseur</option>
                {workers.map((worker) => (
                  <option key={worker.user_id} value={worker.user_id}>
                    {worker.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formClient">
              <Form.Label>Client</Form.Label>
              <Form.Control
                as="select"
                value={newEvent.client || ""}
                onChange={(e) => {
                  const selectedClient = clients.find(
                    (client) => client.idClient === parseInt(e.target.value)
                  );
                  setNewEvent({
                    ...newEvent,
                    client: e.target.value,
                    adresse: selectedClient ? selectedClient.adresse : "",
                    raisonSocial: selectedClient
                      ? selectedClient.raisonSocial
                      : "",
                    gouvernorat: selectedClient
                      ? selectedClient.gouvernorat
                      : "",
                  });
                }}
              >
                <option value="">Sélectionnez un client</option>
                {clients.map((client) => (
                  <option key={client.idClient} value={client.idClient}>
                    {client.raisonSocial} - {client.adresse}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formRaisonSocial">
              <Form.Label>Raison Social</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.raisonSocial || ""}
                readOnly
              />
            </Form.Group>
            <Row className="mb-2">
              <Form.Group controlId="formAdresse" as={Col} md="8">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  type="text"
                  value={newEvent.adresse || ""}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="formGouvernorat" as={Col} md="4">
                <Form.Label>Gouvernorat</Form.Label>
                <Form.Control
                  type="text"
                  value={newEvent.gouvernorat || ""}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Form.Group controlId="formMissionDescription">
              <Form.Label>Description de la mission</Form.Label>
              <Form.Control
                as="select" // Utilisation de "select" pour la liste déroulante
                value={newEvent.missionDescription || ""}
                onChange={(e) =>
                  setNewEvent({
                    ...newEvent,
                    missionDescription: e.target.value,
                  })
                }
              >
                <option value="">-- Sélectionnez une option --</option>
                <option value="Facing & Rupture">
                  {" "}
                  Rapport Facing & Rupture & Quantité
                </option>
                <option value="Rapport Prix">Rapport Prix Concurrentiel</option>
              </Form.Control>
            </Form.Group>
            <Row className="mb-2">
              <Form.Group controlId="formDate" as={Col} md="6">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newEvent.date}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, date: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formTime" as={Col} md="6">
                <Form.Label>Heure</Form.Label>
                <Form.Control
                  type="time"
                  value={newEvent.time}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, time: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              {isEditMode ? "Modifier" : "Ajouter"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette mission ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MissionList;
